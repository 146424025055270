// src/components/LoadingFallback.tsx
const LoadingFallback = ({ componentName }: { componentName: string }) => {
  return (
    <div className="flex justify-center items-center h-full">
      <p className="text-lg text-gray-600">{`Loading ${componentName}...`}</p>
    </div>
  );
};

export default LoadingFallback;
