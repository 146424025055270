import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import NavDropdown from './navDropdown';
import BurgerMenu from './svg/burgerMenu';

// Define types for navbar items
interface NavDropdownItem {
  title: string;
  url: string;
  text: string;
  submenu?: NavDropdownItem[];
}

interface NavDropdownData {
  title: string;
  url: string;
  submenu?: NavDropdownItem[];
}

// Navbar component
const Navbar: React.FC = () => {
  const [navShow, setNavShow] = useState<boolean>(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  const toggle = () => setNavShow(prev => !prev);
  const closeNav = () => {
    setNavShow(false);
    setActiveDropdown(null);
  };

  const toggleDropdown = (dropdown: string) => {
    setActiveDropdown(prev => (prev === dropdown ? null : dropdown));
  };

  const navItems: { [key: string]: NavDropdownData } = {
    About: {
      title: 'About',
      url: '/about',
      submenu: [
        { title: 'About', url: '/about', text: "Explore Genellipse" },
        { title: 'Our People', url: '/people', text: "Explore the people behind Genellipse" },
        { title: 'Careers', url: '/careers', text: "Find opportunities at Genellipse" }
      ]
    },
    Team: {
      title: 'Our People',
      url: '/people',
    },
    Platforms: {
      title: 'Platforms',
      url: '/platforms',
      submenu: [
        { title: 'Platforms', url: '/platforms', text: "Explore Genellipse's Solutions" },
        {
          title: 'GeneAI', url: '/platforms/ai', text: "Innovative Solutions across Industries", submenu: [
            { title: 'GeneCognitives', url: '/platforms/ai/cognitives', text: "Harness the full power of data" },
            { title: 'GeneDigital', url: '/platforms/ai/digital', text: "Driving Digital Transformation" },
            { title: 'GeneAnalytics', url: '/platforms/ai/analytics', text: "State-of-the-art Analytics" },
            { title: 'GeneSecure', url: '/platforms/ai/secure', text: "Unparalleled Data Security" },
            { title: 'GeneData', url: '/platforms/ai/data', text: "Revolutionizing Data Management" },
            { title: 'GeneFormation', url: '/platforms/ai/formation', text: "Formation" },
            { title: 'GeneRobix', url: '/platforms/ai/robix', text: "Precision Robotics Automation" },
            { title: 'GeneChain', url: '/platforms/ai/chain', text: "Cutting-edge Blockchain Platform" }
          ]
        },
        { title: 'KiskadeeAI', url: '/platforms/kiskadee', text: "Genellipse's AI Platform" },
        { title: 'GeneLaw', url: '/platforms/law', text: "Streamlining the Law Workflow" }
      ]
    },
    Solutions: {
      title: 'Solutions',
      url: '/solutions',
      submenu: [
        { title: 'Solutions', url: '/solutions', text: "Explore Genellipse's insights" },
        { title: 'Insurance', url: '/solutions/insurance', text: "Efficient AI-Assisted Claims Processing" },
        { title: 'Banking', url: '/solutions/banking', text: "Smart, Secure Banking Solutions" },
        { title: 'Healthcare', url: '/solutions/healthcare', text: "Predictive Health Analytics" },
        { title: 'Retail', url: '/solutions/retail', text: "Enhancing Customer Experiences" },
        { title: 'Education', url: '/solutions/education', text: "Cutting-Edge AI-driven Innovations" },
        { title: 'Technology', url: '/solutions/technology', text: "Data-Driven Education Solutions" },
      ]
    },
    Services: {
      title: 'Services',
      url: '/services',
      submenu: [
        { title: 'Services', url: '/services', text: "Explore Genellipse's Services" },
        { title: 'Business Consulting', url: '/services/business_consulting', text: "Navigate business complexities" },
        { title: 'Digital Transformation', url: '/services/digital', text: "Automate and streamline operations" },
        { title: 'Infrastructure Management', url: '/services/inframanagement', text: "Optimize IT infrastructure" },
        { title: 'Advanced Analytics', url: '/services/analytics', text: "AI-powered business solutions" },
        { title: 'Security Management', url: '/services/security_infrastructure', text: "Protect your digital assets" },
        { title: 'Data Enablement', url: '/services/data', text: "Unlock actionable insights" },
        { title: 'Artificial Intelligence', url: '/services/ai', text: "Elevating decisions with AI insights" }
      ]
    },
    Contact: {
      title: 'Contact',
      url: '/contact',
    },
  };

  const linkStyles = {
    active: "underline underline-offset-8",
    pending: "underline underline-offset-8",
    normal: "xl:text-geneblack text-geneblack font-space text-[0.875rem] hover:underline hover:underline-offset-8 active:underline active:underline-offset-8 xl:py-[0.65rem] py-2 pl-2"
  };

  return (
    <nav className='shadow bg-genewhite bg-opacity-95 w-screen max-h-24 py-3 flex flex-row sticky top-0 z-50 items-center'>
      <div className='font-varino text-[1.3rem] sm:text-[1.5rem] flex-grow text-geneblue pl-5 lg:pl-32 text-start tracking-[-0.05em]'>
        <NavLink to="/" onClick={closeNav}>GEnEllIPSE</NavLink>
      </div>
      <div onClick={toggle} className='w-[50px] xl:hidden pr-5'>
        <BurgerMenu />
      </div>
      <ul className={`flex flex-wrap h-fit xl:h-fit w-screen xl:w-fit xl:pr-48 max-xl:bg-genenavgray max-xl:flex-col max-xl:bg-opacity-100 max-xl:top-[3.45rem] max-xl:absolute max-xl:right-0 ${navShow ? `` : `hidden`} xl:flex`}>
        <li className='flex flex-wrap flex-row gap-4 h-fit pt-4 pl-2 pb-4 xl:p-0 max-xl:flex-col'>
          {Object.entries(navItems).map(([key, data]) => {
            if (data.submenu) {
                return (
                  <React.Fragment key={key}>
                    <NavDropdown
                    key={data.url}
                      options={data}
                      closeNavToggle={closeNav}
                      isActive={activeDropdown === key.toLowerCase()}
                      toggleDropdown={() => toggleDropdown(key.toLowerCase())}
                    />
                  </React.Fragment>
                )
              }
            else {
              return (
                <NavLink
                key={data.url}
                  to={data.url}
                  onClick={closeNav}
                  className={({ isActive, isPending }) => (`${isActive ? linkStyles.active : isPending ? linkStyles.pending : ''} ${linkStyles.normal}`)}>
                {data.title}
              </NavLink>
          )
        }
      })}

        </li>
      </ul>
    </nav>
  );
}

export default Navbar;