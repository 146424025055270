import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log(`Navigated to ${pathname}`);
    const scrollableElement = document.getElementById('main'); // Or the correct scrollable container
    if (scrollableElement) {
      scrollableElement.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    console.log('Scrolled');
  }, [pathname]);

  return null;
};

export default ScrollToTop;
