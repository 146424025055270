import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, RouteObject } from 'react-router-dom';
import App from './App.tsx';
import './index.css';

// Lazy load for the page components
const Landing = React.lazy(() => import('./pages/Landing.tsx'));
const About = React.lazy(() => import('./pages/About.tsx'));
const Team = React.lazy(() => import('./pages/Team.tsx'));
const Insights = React.lazy(() => import('./pages/Insights.tsx'));
const Careers = React.lazy(() => import('./pages/Careers.tsx'));
const Services = React.lazy(() => import('./pages/Services.tsx'));
const Insurance = React.lazy(() => import('./pages/Insurance.tsx'));
const Banking = React.lazy(() => import('./pages/Banking.tsx'));
const Healthcare = React.lazy(() => import('./pages/Healthcare.tsx'));
const Retail = React.lazy(() => import('./pages/Retail.tsx'));
const Education = React.lazy(() => import('./pages/Education.tsx'));
const Technology = React.lazy(() => import('./pages/Technology.tsx'));
const KiskadeeAI = React.lazy(() => import('./pages/KiskadeeAI.tsx'));
const Solutions = React.lazy(() => import('./pages/Solutions.tsx'));
const Contact = React.lazy(() => import('./pages/Contact.tsx'));
const ComingSoon = React.lazy(() => import('./pages/ComingSoon.tsx'));

// Lazy load for platform-specific pages
const GeneAnalyticsPage = React.lazy(() => import('./pages/SolutionsPages/geneAnalytics/geneAnalyticsPage.tsx'));
const GeneAIPage = React.lazy(() => import('./pages/SolutionsPages/geneAI/geneAIPage.tsx'));
const GeneCognitivesPage = React.lazy(() => import('./pages/SolutionsPages/geneCognitives/geneCognitivesPage.tsx'));
const GeneDataPage = React.lazy(() => import('./pages/SolutionsPages/geneData/geneDataPage.tsx'));
const GeneDigitalPage = React.lazy(() => import('./pages/SolutionsPages/geneDigital/geneDigitalPage.tsx'));
const GeneLawPage = React.lazy(() => import('./pages/SolutionsPages/geneLaw/geneLawPage.tsx'));
const GeneSecurePage = React.lazy(() => import('./pages/SolutionsPages/geneSecure/geneSecurePage.tsx'));
const GeneFormationPage = React.lazy(() => import('./pages/SolutionsPages/geneFormation/geneFormationPage.tsx'));
const GeneRobixPage = React.lazy(() => import('./pages/SolutionsPages/geneRobix/geneRobixPage.tsx'));
const GeneChainPage = React.lazy(() => import('./pages/SolutionsPages/geneChain/geneChainPage.tsx'));

// Lazy load for the generic insights and service page data
const GenericInsights = React.lazy(() => import('./components/templates/genericInsights.tsx'));
const servicePageDataPromise = import('./pages/ServicePagesComponents/servicePageData.json');

import LoadingFallback from './components/loadingFallback.tsx';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Landing />
          </Suspense>
        ),
      },
      {
        path: '/about',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <About />
          </Suspense>
        ),
      },
      {
        path: '/people',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Team />
          </Suspense>
        ),
      },
      {
        path: '/solutions',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Insights />
          </Suspense>
        ),
      },
      {
        path: '/careers',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Careers />
          </Suspense>
        ),
      },
      {
        path: '/services',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Services />
          </Suspense>
        ),
      },
      {
        path: '/solutions/insurance',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Insurance />
          </Suspense>
        ),
      },
      {
        path: '/solutions/banking',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Banking />
          </Suspense>
        ),
      },
      {
        path: '/solutions/healthcare',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Healthcare />
          </Suspense>
        ),
      },
      {
        path: '/solutions/retail',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Retail />
          </Suspense>
        ),
      },
      {
        path: '/solutions/education',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Education />
          </Suspense>
        ),
      },
      {
        path: '/solutions/technology',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Technology />
          </Suspense>
        ),
      },
      {
        path: '/platforms/kiskadee',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <KiskadeeAI />
          </Suspense>
        ),
      },
      {
        path: '/platforms',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Solutions />
          </Suspense>
        ),
      },
      {
        path: '/platforms/ai/analytics',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <GeneAnalyticsPage />
          </Suspense>
        ),
      },
      {
        path: '/platforms/ai',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <GeneAIPage />
          </Suspense>
        ),
      },
      {
        path: '/platforms/ai/cognitives',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <GeneCognitivesPage />
          </Suspense>
        ),
      },
      {
        path: '/platforms/ai/data',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <GeneDataPage />
          </Suspense>
        ),
      },
      {
        path: '/platforms/ai/digital',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <GeneDigitalPage />
          </Suspense>
        ),
      },
      {
        path: '/platforms/law',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <GeneLawPage />
          </Suspense>
        ),
      },
      {
        path: '/platforms/ai/secure',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <GeneSecurePage />
          </Suspense>
        ),
      },
      {
        path: '/platforms/ai/formation',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <GeneFormationPage />
          </Suspense>
        ),
      },
      {
        path: '/platforms/ai/robix',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <GeneRobixPage />
          </Suspense>
        ),
      },
      {
        path: '/platforms/ai/chain',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <GeneChainPage />
          </Suspense>
        ),
      },
      {
        path: '/contact',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <Contact />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense fallback={<LoadingFallback componentName="Page" />}>
            <ComingSoon />
          </Suspense>
        ),
      },
    ],
  },
]);

// Fetch and map the service page data dynamically after lazy loading it
servicePageDataPromise.then(({ default: servicePageData }) => {
  servicePageData.pagesData.map((pageObject) => {
    const servicePage: RouteObject = {
      id: `service-page-${pageObject.path}`, // Ensure unique id
      path: pageObject.path,
      element: (
        <Suspense fallback={<LoadingFallback componentName="Page" />}>
          <GenericInsights
            key={pageObject.path} // Ensure unique key
            textObject={pageObject.pageData.textObjects}
            textContent={pageObject.pageData.textContent}
            imgOrderOne={true}
            imgOrderTwo={false}
            imgurlOne={`/sectionImages/services${pageObject.title.split(' ')[0]}One.png`}
            imgurlTwo={`/sectionImages/services${pageObject.title.split(' ')[0]}Two.png`}
            linkOne='/contact'
            linkTwo={`/platforms/${pageObject.buttonLink}`}
          />
        </Suspense>
      ),
    };
    router.routes[0].children?.push(servicePage);
  });

  // Render the application
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>,
  );
});