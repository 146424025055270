import NavBar from './components/NavBar'
import { Outlet } from "react-router-dom";
import ScrollToTop from './components/scrollToTop';

function App() {

  return (
    <>
      <ScrollToTop />
      <div id={'main'} className='relative h-screen overflow-x-hidden overflow-y-auto'>
        <NavBar />
        <Outlet />
      </div>
    </>
  )
}

export default App
