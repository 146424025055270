import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";

type Props = {
  options: {
    title: string;
    url: string;
    submenu?: {
      title: string;
      url: string;
      text: string;
      submenu?: { title: string; url: string; text: string }[];
    }[];
  };
  isActive: boolean;
  toggleDropdown: () => void;
  closeNavToggle: () => void;
};
let normalLinkStyle =
  "font-space text-[14px] hover:underline hover:underline-offset-8 active:underline active:underline-offset-8";

const NavDropdown = ({
  options,
  isActive,
  toggleDropdown,
  closeNavToggle,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isActive) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [isActive]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    toggleDropdown();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      e.preventDefault();
      toggleOpen();
    }
  };

  return (
    <div
      onMouseOver={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
      aria-haspopup="true"
      aria-expanded={isOpen}
      aria-controls={`${options.title}-menu`}
      onKeyDown={handleKeyDown}
      className="max-sm:relative xl:inline-flex w-fit inline-flex font-space text-geneblack xl:text-geneblack"
    >
      <div className="flex flex-col h-fit">
        <div className="flex flex-row">
          <button className="w-fit py-2 px-2 text-start">
            <NavLink
              to={options.url}
              className={`${
                isActive ? "underline underline-offset-8" : ""
              } ${normalLinkStyle}`}
            >
              {options.title}
            </NavLink>
          </button>
          {options.submenu && (
            <button
              aria-label={`${options.title}-dropdown-button`}
              className="text-[0.9rem] mt-[0.15rem]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="white"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>
          )}
        </div>
        {isOpen && (
          <div
            id={`${options.title}-menu`}
            role="menu"
            aria-labelledby={`${options.title}-button`}
            ref={wrapperRef}
            className="relative overflow-y-auto overflow-x-hidden max-h-[30vh] lg:max-h-[70vh] flex flex-wrap xl:absolute xl:top-[3rem] xl:left-0 z-10 mt-1 w-full origin-top-right py-4 pb-5 bg-genewhite bg-opacity-95 shadow-lg justify-center"
          >
            <div
              className={`w-screen xl:w-fit h-full flex flex-row flex-wrap xl:flex-row gap-6 xl:gap-x-2 xl:gap-y-12 font-space ml-[6%] xl:mx-[6%] text-[1rem]`}
            >
              {options.submenu && (
                <NestedDropdown
                  submenu={options.submenu}
                  toggleDropdown={closeNavToggle}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const NestedDropdown = ({
  submenu,
  toggleDropdown,
}: {
  submenu: {
    title: string;
    url: string;
    text: string;
    submenu?: { title: string; url: string; text: string }[];
  }[];
  toggleDropdown: () => void;
}) => {
  const [nestedIsOpen, setNestedIsOpen] = useState<{ [key: number]: boolean }>(
    {}
  );

  const togglingNested = (index: number) => {
    setNestedIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <div>
      <div className="flex flex-wrap">
        {submenu.map((option, index) => (
          <div key={option.title} className="flex flex-col w-[300px] p-4">
            <div className="flex flex-col">
              <span className="flex flex-row gap-2">
                <NavLink
                  onClick={() => toggleDropdown()}
                  to={option.url}
                  className={normalLinkStyle + ` text-[20px] text-geneblue`}
                >
                  {option.title}
                </NavLink>
                {option.submenu && (
                  <button
                    aria-label={`${option.title}-dropdown-button`}
                    onClick={() => togglingNested(index)}
                    className="text-[0.9rem] mt-[0.15rem] text-geneblue"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="white"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </button>
                )}
              </span>
              <span className="text-[0.7rem] text-genedarkgray w-[225px] flex text-start pr-8 xl:pr-0">
                {option.text}
              </span>
            </div>
          </div>
        ))}
        {submenu.map((option, index) => (
          <>
            {option.submenu && nestedIsOpen[index] && (
              <div className="w-full relative overflow-y-auto overflow-x-hidden max-h-[30vh] lg:max-h-[70vh] flex flex-wrap z-10 mt-4 origin-top-right py-4 pb-5 bg-genewhite bg-opacity-95 shadow-lg justify-center">
                <div
                  className={`w-screen xl:w-fit h-full flex flex-row flex-wrap xl:flex-row gap-6 xl:gap-x-2 xl:gap-y-12 font-space ml-[6%] xl:ml-[4%] text-[1rem]`}
                >
                  <NestedDropdown
                    submenu={option.submenu}
                    toggleDropdown={toggleDropdown}
                  />
                </div>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default NavDropdown;
